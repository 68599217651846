<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-card>
                  <v-card-title class="h6 mb-n1">
                    Comprobante cancelatorio de facturación OS
                  </v-card-title>
                  <v-card-text>
                    <v-row class="mb-n4">
                      <!-- Agente de facturacion -->
                      <v-col cols="3" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Agente de facturación"
                          v-model="agenteFacturacion"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Tipo de iva del agente -->
                      <v-col cols="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Condición de IVA"
                          v-model="tipoIvaAgente"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- CUIT -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="CUIT"
                          v-model="CuitAgente"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Direccion -->
                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Dirección"
                          v-model="direcAgente"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n4">
                      <!-- Empresa -->
                      <v-col cols="12" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Entidad facturante"
                          v-model="empresa"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Tipo de iva de empresa -->
                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Condición de IVA"
                          v-model="tipoIvaEmpresa"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- CUIT de empresa -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="CUIT"
                          v-model="cuitEmpresa"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- direccion de empresa -->
                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Dirección"
                          v-model="direcEmpresa"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-card>
                  <v-card-title class="h6 mt-n2 mb-n1">
                    Comprobante
                  </v-card-title>
                  <v-card-text>
                    <!-- Solo para cuando es RECIBOS -->
                    <v-row class="mb-n4" v-if="this.isRecibo">
                      <!-- Tipo de comprobante -->
                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Tipo de comprobante"
                          v-model="tipoComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- numero de comprobante -->
                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Número de comprobante"
                          v-model="numeroComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- fecha -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Fecha"
                          v-model="fechaComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Importe sin relacionar -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          class="right-input"
                          filled
                          readonly
                          label="Importe sin relacionar"
                          v-model="importeSinRela"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Total -->
                      <v-col
                        cols="12"
                        :md="this.isNotaCredito ? 3 : 2"
                        class="py-0"
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          class="right-input"
                          filled
                          readonly
                          label="Total"
                          v-model="total"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Solo para cuando es NOTA DE CREDITO -->
                    <v-row class="mb-n4" v-if="this.isNotaCredito">
                      <!-- Tipo de comprobante -->
                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Tipo de comprobante"
                          v-model="tipoComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- numero de comprobante -->
                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Número de comprobante"
                          v-model="numeroComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- fecha -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Fecha"
                          v-model="fechaComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- periodo -->
                      <v-col
                        cols="12"
                        md="2"
                        class="py-0"
                        v-if="!this.isRecibo"
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Período"
                          v-model="periodo"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Importe sin relacionar -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          class="right-input"
                          filled
                          readonly
                          label="Importe sin relacionar"
                          v-model="importeSinRela"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n4" v-if="this.isNotaCredito">
                      <!-- NETO -->
                      <v-col
                        cols="12"
                        md="3"
                        class="py-0"
                        v-if="this.isNotaCredito"
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          class="right-input"
                          filled
                          readonly
                          label="Neto"
                          v-model="neto"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- IVA -->
                      <v-col
                        cols="12"
                        md="2"
                        class="py-0"
                        v-if="this.isNotaCredito"
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          class="right-input"
                          filled
                          readonly
                          label="IVA"
                          v-model="iva"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- % percepcion IIBB -->
                      <v-col
                        cols="12"
                        md="2"
                        class="py-0"
                        v-if="this.isNotaCredito"
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Porc. Percepción IIBB"
                          v-model="porcPercepIIBB"
                          autocomplete="off"
                          class="right-input"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Perc.IIBB -->
                      <v-col
                        cols="12"
                        md="2"
                        class="py-0"
                        v-if="this.isNotaCredito"
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          class="right-input"
                          filled
                          readonly
                          label="Percepción IIBB"
                          v-model="percepcionIIBB"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Total -->
                      <v-col
                        cols="12"
                        :md="this.isNotaCredito ? 3 : 2"
                        class="py-0"
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          class="right-input"
                          filled
                          readonly
                          label="Total"
                          v-model="total"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <!-- Moneda del comprobante -->
                      <v-col
                        cols="12"
                        md="3"
                        class="py-0"
                        v-if="this.paramGralMultimoneda"
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Moneda de comprobante"
                          v-model="monedaComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Fecha de cotizacion -->
                      <v-col
                        cols="12"
                        md="2"
                        class="py-0"
                        v-if="this.paramGralMultimoneda"
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Fecha cotización"
                          v-model="fechaCotizacion"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Moneda de consulta -->
                      <v-col
                        cols="12"
                        md="3"
                        class="py-0"
                        v-if="this.paramGralMultimoneda"
                      >
                        <v-select
                          v-model="monedaConsultaSelected"
                          :items="monedasConsultaItems"
                          item-text="value"
                          item-value="id"
                          return-object
                          label="Moneda de consulta"
                          @change="setComprobanteByMoneda()"
                          outlined
                          dense
                        >
                        </v-select>
                        <p class="invalidConfigText mt-n5" v-if="this.haveCotiza">
                          Sin cotización
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <!-- Texto o detalle de percepciones -->
                  <!-- Por ahora no tienen un @click porque lo de percepciones no se implementa -->
                  <!-- Solo se muestra texto1, texto2, texto3 => corresponde a btn texto -->
                  <v-row class="mt-n6">
                    <v-col col="12" class="pt-0 pb-0">
                      <v-btn-toggle dense v-model="text" color="primary" group>
                        <v-btn value="Texto"> Texto del comprobante </v-btn>
                        <v-btn
                          value="DetallePercep"
                          disabled
                          v-if="!this.isRecibo"
                        >
                          Detalle de percepciones
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- TEXTO 1 -->
                    <v-col cols="12" md="12" class="py-0 mb-n5">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        v-model="texto1"
                        autocomplete="off"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <!-- TEXTO 2 -->
                    <v-col cols="12" md="12" class="py-0 mb-n5">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        v-model="texto2"
                        autocomplete="off"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <!-- TEXTO 3 -->
                    <v-col cols="12" md="12" class="py-0 mb-n3">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        v-model="texto3"
                        autocomplete="off"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <!-- tabla de comprobantes relacionados -->
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
                v-if="this.itemsComprobantesRela.length !== 0"
              >
                <v-card>
                  <v-card-title class="h6 mt-n2 mb-n2">
                    Comprobantes relacionados
                  </v-card-title>
                  <v-data-table
                    :headers="headersComprobantesRela"
                    :items="itemsComprobantesRela"
                    height="100%"
                    fixed-header
                    class="elevation-1"
                    :loading="isLoadingTable"
                  >
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <!-- tabla de conceptos del comprobante -->
            <v-row
              v-if="this.isNotaCredito && this.itemsConceptos.length !== 0"
            >
              <v-col cols="12" class="pt-0 mt-n2">
                <v-card>
                  <v-card-title class="h6 mt-n2 mb-n2">
                    Conceptos del comprobante
                  </v-card-title>
                  <v-data-table
                    :headers="headersConceptos"
                    :items="itemsConceptos"
                    height="100%"
                    fixed-header
                    class="elevation-1"
                    :loading="isLoadingTable"
                    hide-default-footer
                  >
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <!-- tabla de tipo de valor -->
            <v-row v-if="this.isRecibo">
              <v-col cols="12" class="pt-0 mt-n2">
                <v-card>
                  <v-card-title class="h6 mt-n2 mb-n2">
                    Valores recibidos
                  </v-card-title>
                  <v-data-table
                    :headers="headersTipoValor"
                    :items="itemsTipoValor"
                    height="100%"
                    fixed-header
                    class="elevation-1"
                    :loading="isLoadingTable"
                    hide-default-footer
                  >
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <!-- datos del CAE -->
            <v-row class="mb-n10" v-if="this.haveCAE">
              <v-col cols="4" class="d-flex">
                <p class="label">Número de CAE:</p>
                <strong class="ml-2 mb-0">{{ numeroCAE }}</strong>
              </v-col>
              <v-col cols="6" class="d-flex">
                <p class="label">Fecha de vencimiento CAE:</p>
                <strong class="ml-2 mb-0">{{ fechaVencCAE }}</strong>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import { mapActions } from "vuex";
export default {
  name: "DetalleComprobanteCancelaFacturacionOS",
  components: {
    GoBackBtn,
  },
  data() {
    return {
      routeToGo: this.$route.params.goBackName,
      agenteFacturacion: null,
      tipoIvaAgente: null,
      CuitAgente: null,
      direcAgente: null,
      empresa: null,
      tipoIvaEmpresa: null,
      cuitEmpresa: null,
      direcEmpresa: null,
      monedaComprobante: null,
      fechaCotizacion: null,
      monedaConsultaSelected: null,
      monedasConsultaItems: [],
      tipoComprobante: null,
      numeroComprobante: null,
      fechaComprobante: null,
      periodo: null,
      percepcionIIBB: null,
      porcPercepIIBB: null,
      neto: null,
      iva: null,
      total: null,
      texto1: null,
      texto2: null,
      texto3: null,
      text: "Texto",
      numeroCAE: null,
      fechaVencCAE: null,
      importeSinRela: null,
      paramGralMultimoneda: false,
      haveCAE: false,
      isLoadingTable: false,
      isNotaCredito: false,
      isRecibo: false,
      haveCotiza: false,
      itemsComprobantesRela: [],
      headersComprobantesRela: [
        {
          text: "Comprobante",
          sortable: false,
          value: "pfNroComp",
        },
        {
          text: "Fecha de vencimiento",
          sortable: false,
          value: "fechaVto",
          align: "end",
        },
        {
          text: "Neto",
          sortable: false,
          value: "neto",
          align: "end",
        },
        {
          text: "IVA",
          sortable: false,
          value: "iva",
          align: "end",
        },
        {
          text: "Perc. IIBB",
          sortable: false,
          value: "percIb",
          align: "end",
        },
        {
          text: "Total",
          sortable: false,
          value: "total",
          align: "end",
        },
        {
          text: "Cancela",
          sortable: false,
          value: "cancela",
          align: "end",
        },
      ],
      itemsConceptos: [],
      headersConceptos: [
        {
          text: "Concepto",
          sortable: false,
          value: "concepto",
        },
        {
          text: "Cantidad",
          sortable: false,
          value: "pfCompiCant",
          align: "end",
        },
        {
          text: "Precio unitario",
          sortable: false,
          value: "pfCompiUnit",
          align: "end",
        },
        {
          text: "Neto",
          sortable: false,
          value: "pfCompiNeto",
          align: "end",
        },
        {
          text: "Porcentaje de IVA",
          sortable: false,
          value: "ivaPorc",
          align: "end",
        },
        {
          text: "IVA",
          sortable: false,
          value: "pfCompiIva",
          align: "end",
        },
        {
          text: "Total",
          sortable: false,
          value: "pfCompiTotal",
          align: "end",
        },
      ],
      itemsTipoValor: [],
      headersTipoValor: [
        {
          text: "Valor recibido",
          sortable: false,
          value: "tipoValNom",
        },
        {
          text: "Código del banco",
          sortable: false,
          value: "bancoCod",
        },
        {
          text: "Nombre del banco",
          sortable: false,
          value: "bancoNom",
        },
        {
          text: "Fecha",
          sortable: false,
          value: "fecha",
        },
        {
          text: "Número",
          sortable: false,
          value: "numero",
        },
        {
          text: "Importe",
          sortable: false,
          value: "importe",
          align: "end",
        },
      ],
    };
  },
  created() {
    this.setDetalleComprobante();
  },
  methods: {
    ...mapActions({
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda",
      getComprobanteCancelaFacturacionOSById:
        "facturacionOS/getComprobanteCancelaFacturacionOSById",
    }),
    async setDetalleComprobante() {
      const dataMonedas = await this.getMonedasTaxonomy("habListado");
      this.monedasConsultaItems = dataMonedas;
      const responseParametros = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
      const data = {
        pfCompId: this.$route.params.pfCompId,
        monedaId: this.$route.params.monedaToDetalle
          ? this.$route.params.monedaToDetalle.id
          : -1,
        fechaCotizacion: this.$route.params.fechaCotizaToDetalle
          ? this.$route.params.fechaCotizaToDetalle
          : null,
      };
      this.isLoadingTable = true;
      const response = await this.getComprobanteCancelaFacturacionOSById(data);
      if (response.tipoDeComp == "Recibo") {
        this.isRecibo = true;
        this.itemsTipoValor = response.tipoValoresItemsRecibo;
      } else if (response.tipoDeComp == "Nota de credito") {
        this.isNotaCredito = true;
        this.itemsConceptos = response.conceptosItemsNC;
      }
      if (response.nroCAE != "") {
        this.haveCAE = true;
        this.numeroCAE = response.nroCAE;
        this.fechaVencCAE = response.fecVenCAE;
      }
      if (response.haveCotiza == 0) this.haveCotiza = true;
      this.itemsComprobantesRela = response.comprobantesDeudaRela;
      this.isLoadingTable = false;
      this.agenteFacturacion = response.ageFactNombre;
      this.tipoIvaAgente = response.ageFactTIvaNom;
      this.CuitAgente = response.ageFactCuit;
      this.direcAgente = response.ageFactDirec;
      this.empresa = response.entFacNombre;
      this.tipoIvaEmpresa = response.entFacTIvaNom;
      this.cuitEmpresa = response.entFacCuit;
      this.direcEmpresa = response.entFacDirec;
      this.monedaComprobante = response.monedaNombre;
      this.fechaCotizacion = this.formatDate(
        this.$route.params.fechaCotizaToDetalle
      );
      this.monedaConsultaSelected = this.$route.params.monedaToDetalle;
      this.tipoComprobante = response.tCompNombre;
      this.numeroComprobante = response.pfNroComp;
      this.fechaComprobante = response.pfFecha;
      this.periodo = response.periodo;
      this.total = response.pfTotal;
      this.percepcionIIBB = response.percIb;
      this.porcPercepIIBB = response.alicPercIb;
      this.neto = response.pfNeto;
      this.iva = response.pfIva;
      this.texto1 = response.pfTexto1;
      this.texto2 = response.pfTexto2;
      this.texto3 = response.pfTexto3;
      this.importeSinRela = response.importeSinRela;
    },
    async setComprobanteByMoneda() {
      this.haveCotiza = false;
      const data = {
        pfCompId: this.$route.params.pfCompId,
        monedaId: this.monedaConsultaSelected
          ? this.monedaConsultaSelected.id
          : -1,
        fechaCotizacion: this.$route.params.fechaCotizaToDetalle
          ? this.$route.params.fechaCotizaToDetalle
          : null,
      };
      const response = await this.getComprobanteCancelaFacturacionOSById(data);
      if (this.isRecibo) this.itemsTipoValor = response.tipoValoresItemsRecibo;
      else if (this.isNotaCredito)
        this.itemsConceptos = response.conceptosItemsNC;
      if (response.haveCotiza == 0) this.haveCotiza = true;
      this.itemsComprobantesRela = response.comprobantesDeudaRela;
      this.total = response.pfTotal;
      this.percepcionIIBB = response.percIb;
      this.neto = response.pfNeto;
      this.iva = response.pfIva;
      this.importeSinRela = response.importeSinRela;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
.right-input >>> input {
  text-align: right;
}
.blue-text {
  color: blue;
  text-decoration: underline blue;
}
.btnDisabled {
  color: #100f0f99;
}
.label {
  opacity: 0.7;
}
.invalidConfigText {
  color: red;
}
</style>